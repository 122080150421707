import { AxiosPromise } from 'axios';
import { Customer } from '@/model/customer';
import axios from '@/axios';
import { CustomerForm } from '@/model/customer-form';
import { ProposalExportType } from '@/model/proposal-export-type';
import { Supplier } from '@/model/supplier';

const customersService = {
    getList(search: string | undefined, country: string | undefined): AxiosPromise<Customer[]> {
        return axios.get<Customer[]>('/customers', {
            params: {search, country}
        });
    },
    getOne(customerId: string): AxiosPromise<Customer> {
        return axios.get<Customer>(`/customers/${customerId}`);
    },
    save(request: CustomerForm): AxiosPromise<Customer> {
        return axios.post<Customer>(`/customers`, request)
    },
    export(customerId: string, type: ProposalExportType): AxiosPromise<string> {
        return axios.get(`/customers/${customerId}/export/${type}`, {
            responseType: 'blob', // had to add this one here
        });
    },
    getCustomersSuppliers(customerId: string): AxiosPromise<Supplier[]> {
        return axios.get<Supplier[]>(`/customers/${customerId}/suppliers`);
    },
}

export default customersService;