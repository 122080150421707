
import { defineComponent } from 'vue';
import LoaderLabel from '@/components/ui/LoaderLabel.vue';

export default defineComponent({
  name: "ContentLoader",
  components: {LoaderLabel},
  props: {
    label: {
      type: String,
      default: 'general.saving'
    },
  }
})
