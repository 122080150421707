import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-46e02bb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "customer-details" }
const _hoisted_2 = { class: "customer-details__section" }
const _hoisted_3 = { class: "flex flex-row items-center justify-center" }
const _hoisted_4 = { class: "customer-details__section__group w-1/2" }
const _hoisted_5 = { class: "customer-details__section__group__label" }
const _hoisted_6 = { class: "customer-details__section__group__value customer-details__section__group__value--name" }
const _hoisted_7 = { class: "customer-details__section__group w-1/2" }
const _hoisted_8 = { class: "customer-details__section__group__label" }
const _hoisted_9 = { class: "customer-details__section__group__value" }
const _hoisted_10 = { class: "flex flex-row items-center justify-center mt-4" }
const _hoisted_11 = { class: "customer-details__section__group w-1/2" }
const _hoisted_12 = { class: "customer-details__section__group__label" }
const _hoisted_13 = { class: "customer-details__section__group__value" }
const _hoisted_14 = { class: "customer-details__section__group w-1/2" }
const _hoisted_15 = { class: "customer-details__section__group__label" }
const _hoisted_16 = { class: "customer-details__section__group__value" }
const _hoisted_17 = { class: "customer-details__section mt-3" }
const _hoisted_18 = { class: "flex flex-row items-center justify-center mt-4" }
const _hoisted_19 = { class: "customer-details__section__group w-1/2" }
const _hoisted_20 = { class: "customer-details__section__group__label" }
const _hoisted_21 = { class: "customer-details__section__group__value" }
const _hoisted_22 = { class: "customer-details__section__group w-1/2" }
const _hoisted_23 = { class: "customer-details__section__group__label" }
const _hoisted_24 = { class: "customer-details__section__group__value" }
const _hoisted_25 = { class: "customer-details__section mt-3" }
const _hoisted_26 = { class: "flex flex-row items-center justify-center" }
const _hoisted_27 = { class: "customer-details__section__group w-1/2" }
const _hoisted_28 = { class: "customer-details__section__group__label" }
const _hoisted_29 = { class: "customer-details__section__group__value" }
const _hoisted_30 = { class: "customer-details__section__group w-1/2" }
const _hoisted_31 = { class: "customer-details__section__group__label" }
const _hoisted_32 = { class: "customer-details__section__group__value" }
const _hoisted_33 = { class: "flex flex-row items-center mt-4" }
const _hoisted_34 = { class: "customer-details__section__group w-1/2" }
const _hoisted_35 = { class: "customer-details__section__group__label" }
const _hoisted_36 = { class: "customer-details__section__group__value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("section", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('customer.details.name')), 1),
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.customer.companyName), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('customer.details.email')), 1),
          _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.customer.contactEmail), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('customer.details.phone')), 1),
          _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.customer.phone), 1)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, _toDisplayString(_ctx.$t('customer.details.tax_id')), 1),
          _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.customer.taxId), 1)
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_17, [
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('customer.details.country_name')), 1),
          _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.customer.country?.name), 1)
        ]),
        _createElementVNode("div", _hoisted_22, [
          _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('customer.details.address')), 1),
          _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.customer.address), 1)
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_25, [
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("div", _hoisted_27, [
          _createElementVNode("div", _hoisted_28, _toDisplayString(_ctx.$t('customer.details.contact_name')), 1),
          _createElementVNode("div", _hoisted_29, _toDisplayString(_ctx.customer.contactName), 1)
        ]),
        _createElementVNode("div", _hoisted_30, [
          _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.$t('customer.details.contact_position')), 1),
          _createElementVNode("div", _hoisted_32, _toDisplayString(_ctx.customer.contactPosition), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_33, [
        _createElementVNode("div", _hoisted_34, [
          _createElementVNode("div", _hoisted_35, _toDisplayString(_ctx.$t('customer.details.contact_email')), 1),
          _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.customer.contactEmail), 1)
        ])
      ])
    ])
  ]))
}