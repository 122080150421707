
import { defineComponent, PropType } from 'vue';
import { Customer } from '@/model/customer';

export default defineComponent({
  name: "CustomerDetailsModal",
  props: {
    customer: {
      required: true,
      type: Object as PropType<Customer>
    }
  }
})
