
import { defineComponent } from 'vue';
import { debounce } from 'lodash';

export default defineComponent({
  name: "SearchInput",
  props: {
    placeholder: {
      type: String,
      default: 'general.search'
    },
    modelValue: {
      type: String
    },
    debounceTime: {
      type: Number,
      default: 400
    }
  },
  data: function () {
    return {
      debouncedEmit: debounce((value: string) => {
        this.$emit("update:modelValue", value);
      }, this.debounceTime),
    };
  },
  beforeUnmount(): void {
    this.debouncedEmit.cancel();
  },
  computed: {
    search: {
      get(): string | undefined {
        return this.modelValue;
      },
      set(value: string) {
        this.debouncedEmit(value);
      }
    }
  }
})
