import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5387b84d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown-menu" }
const _hoisted_2 = {
  key: 0,
  class: "dropdown-menu__content"
}
const _hoisted_3 = { class: "dropdown-menu__content__actions" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "dropdown-menu__content__actions__action__label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_click_outside_component = _resolveDirective("click-outside-component")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "dropdown-menu__trigger",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.show = !_ctx.show))
    }, [
      _renderSlot(_ctx.$slots, "trigger", {}, undefined, true)
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.show)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("ul", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.actions, (action) => {
                  return (_openBlock(), _createElementBlock("li", {
                    key: `dropdown-menu-${_ctx.id}-${action.id}`,
                    class: _normalizeClass(["dropdown-menu__content__actions__action", {'dropdown-menu__content__actions__action--separate': action.separate, 'dropdown-menu__content__actions__action--danger' : 'danger' === action.type}]),
                    onClick: action.action
                  }, [
                    _createElementVNode("div", null, [
                      _createElementVNode("span", {
                        class: _normalizeClass(["icon text-medium", `icon-${action.icon}`])
                      }, null, 2),
                      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(action.label)), 1)
                    ])
                  ], 10, _hoisted_4))
                }), 128))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ])), [
    [_directive_click_outside_component, _ctx.close]
  ])
}