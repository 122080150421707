
import { defineComponent, PropType } from 'vue';
import { clickOutsideComponent } from '@/directives/click-outside.directive';

export interface Action {
  id: string;
  icon: string;
  type: string | undefined;
  label: string;
  separate: boolean;
  action(): void
}

export default defineComponent({
  name: "DropdownMenu",
  directives: {
    clickOutsideComponent
  },
  data(): { show: boolean } {
    return {
      show: false
    }
  },
  props: {
    actions: {
      required: true,
      type: Object as PropType<Action[]>
    },
    id: {
      required: true,
      type: String
    }
  },
  methods: {
    close() {
      this.show = false;
    }
  }
})
