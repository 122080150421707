import { Directive } from 'vue';

export const clickOutsideComponent: Directive = {
    mounted(el, binding, vnode) {
        el.clickOutsideEvent = function (event: PointerEvent) {
            if (!(vnode.el == event.target || vnode.el.contains(event.target))) {
                binding.value(event);
            }
        }
        document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent)
    }
}